import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import readers from "../images/chris-benson-nKEARsgmrqc-unsplash.jpg";
import { graphql } from "gatsby";
import cx from "classnames";
import ContentLayout from "../components/content-layout"

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "inline-block text-center mt-3 py-4 px-6 bg-red-700 hover:bg-red-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <a className={className} {...props}>
      {children}
    </a>
  );
};

const Book = ({ title, image, uri, publisher, pubDate, isbn, children }) => {
  return (
    <div className="w-full md:grid md:grid-cols-4 md:gap-8 border-b mb-4">
      <div className="col-span-1">
        <img src={image} alt={title} className="border p-1" />
        <StyledButton href={uri}>Buy direct from publisher</StyledButton>
      </div>
      <div className="col-span-3">
        <h2 className="text-3xl md:text-4xl font-title">{title}</h2>
        <p className="text-sm text-gray-500 my-2">
          {publisher} &middot; {pubDate} &middot; ISBN {isbn}
        </p>
        <div className="markdown-body">
          <div dangerouslySetInnerHTML={{ __html: children }}></div>
        </div>
      </div>
    </div>
  );
};

const Books = ({ data }) => {
  const books = data.allMarkdownRemark.edges;

  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Books" />
      <ContentLayout title={`Read Our Books`} image={readers}>
        <div className="flex flex-wrap mt-10 md:mt-28">
          {books.map(({ node }, i) => (
            <Book
              title={node.frontmatter.title}
              image={node.frontmatter.image}
              uri={node.frontmatter.uri}
              key={i}
              publisher={node.frontmatter.publisher}
              pubDate={node.frontmatter.pubDate}
              isbn={node.frontmatter.isbn}
            >
              {node.html}
            </Book>
          ))}
        </div>
      </ContentLayout>
    </Layout>
  );
};

export const query = graphql`
  query BooksQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/books/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            image
            uri
            publisher
            pubDate
            isbn
          }
          html
        }
      }
    }
  }
`;
export default Books;
